













































































import { Component, Vue } from "vue-property-decorator";
import { Application, IconsType } from "@/types";
import {
  mdiCloseCircleOutline,
  mdiFormatListChecks,
  mdiRefresh
} from "@mdi/js";
import { DataTableHeader } from "vuetify";
import NumberFormat = Intl.NumberFormat;
import { namespace } from "vuex-class";
const AdminModule = namespace("AdminModule");
const AppModule = namespace("AppModule");
@Component({
  components: {
    MenuOptions: () => import("@/components/Menu/MenuOptions.vue"),
    CardFilters: () => import("@/components/Menu/MenuFilters.vue"),
    LabelStatus: () => import("@/components/Label/LabelStatus.vue"),
    LabelZip: () => import("@/components/Label/LabelZip.vue")
  }
})
export default class VAdmin extends Vue {
  //
  // COMPUTED
  @AppModule.Getter
  public smallScreen!: boolean;
  @AppModule.State
  public currencyFormatter!: NumberFormat;
  @AdminModule.State
  public applications!: [];
  @AdminModule.State
  public totalLength!: number;
  get headers(): DataTableHeader[] {
    return [
      {
        value: "author.familyName",
        text: this.$t("pages.admin.table-headers.author") as string
      },
      {
        value: "author.tabel",
        text: this.$t("pages.admin.table-headers.tabel") as string,
        sortable: false
      },
      {
        value: "author.department",
        text: this.$t("pages.admin.table-headers.department") as string,
        sortable: false
      },
      {
        value: "item.nameFull",
        text: this.$t("pages.admin.table-headers.item") as string
      },
      {
        value: "price",
        text: this.$t("pages.admin.table-headers.price") as string
      },
      {
        value: "status",
        text: this.$t("pages.admin.table-headers.status") as string
      },
      {
        value: "createdDate",
        text: this.$t("pages.admin.table-headers.created-date") as string
      },
      {
        value: "documents",
        text: this.$t("pages.admin.table-headers.documents") as string,
        sortable: false
      }
    ];
  }
  //
  // DATA
  private icons: IconsType = {
    closeCircleOutline: mdiCloseCircleOutline,
    refresh: mdiRefresh,
    listCheck: mdiFormatListChecks
  };
  private loading = false;
  private loadingPayBtn = false;
  private showSelect = false;
  private selectedItems = [];
  //
  // METHODS
  toggleShowSelect() {
    if (this.showSelect) {
      this.selectedItems = [];
    }
    this.showSelect = !this.showSelect;
  }
  markAsPaid() {
    this.loadingPayBtn = true;
    setTimeout(async () => {
      await this.$store.dispatch(
        "AdminModule/setStatusPaid",
        this.selectedItems.map((item: Application) => item.id)
      );
      this.loadingPayBtn = false;
      this.showSelect = false;
      this.selectedItems = [];
      this.updateApplications();
    }, 0);
  }
  toLocaleTime(time: string) {
    return new Date(time).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  updateApplications() {
    this.loading = true;
    setTimeout(async () => {
      await this.$store.dispatch("AdminModule/fetchApplications");
      this.loading = false;
    }, 0);
  }
  openApplication(me: MouseEvent, { item }: { item: Application }) {
    window.open(`/application/${item.number}`);
  }
  async onUpdateSort(payload: any) {
    let sortBy;
    switch (payload.sortBy[0]) {
      case "author.familyName":
        sortBy = "user";
        break;
      case "item.nameShort":
        sortBy = "item";
        break;
      case "price":
        sortBy = "price";
        break;
      case "status":
        sortBy = "status";
        break;
      case "createdDate":
        sortBy = "date";
        break;
      default:
        sortBy = "date";
    }
    this.$store.commit("FiltersModule/FILTER_PAGE", payload.page);
    this.$store.commit("FiltersModule/FILTER_SORT_BY", sortBy);
    this.$store.commit(
      "FiltersModule/FILTER_ITEMS_PER_PAGE",
      payload.itemsPerPage
    );
    this.$store.commit(
      "FiltersModule/FILTER_SORT_TYPE",
      payload.sortDesc[0] ? "desc" : "asc"
    );
    await this.updateApplications();
  }
  //
  // LIFECYCLE HOOKS
  created() {
    this.updateApplications();
  }
}

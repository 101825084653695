var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":"","fullscreen":""}},[_c('v-data-table',{attrs:{"items":_vm.applications,"headers":_vm.headers,"loading":_vm.loading,"sort-by":"createdDate","sort-desc":"","must-sort":"","server-items-length":_vm.totalLength,"footer-props":{
      itemsPerPageOptions: [10, 30, 100],
      showCurrentPage: true
    },"show-select":_vm.showSelect && !_vm.smallScreen},on:{"dblclick:row":_vm.openApplication,"update:options":_vm.onUpdateSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"admin-toolbar mb-3",attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("pages.admin.title"))+" ")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.showSelect && !_vm.smallScreen && _vm.selectedItems.length > 0)?_c('v-btn',{attrs:{"text":"","loading":_vm.loadingPayBtn},on:{"click":_vm.markAsPaid}},[_vm._v(" "+_vm._s(_vm.$t("pages.admin.btn-paid", { n: _vm.selectedItems.length }))+" ")]):_vm._e(),(!_vm.smallScreen)?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggleShowSelect}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.listCheck))])],1):_vm._e(),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.updateApplications}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.refresh))])],1),_c('CardFilters',{on:{"filter":_vm.updateApplications}}),_c('MenuOptions')],1)],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-icon',{staticClass:"mt-5 mb-2 mx-auto",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icons.refresh))]),_c('p',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("loading-data")))])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-icon',{staticClass:"mt-5 mb-2 mx-auto",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icons.closeCircleOutline))]),_c('p',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("no-data")))])]},proxy:true},{key:"item.author.familyName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.familyName)+" "+_vm._s(item.author.firstName)+" ")]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormatter.format(item.price))+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('LabelStatus',{attrs:{"status":item.status,"application-id":item.id},on:{"status-change":_vm.updateApplications}})]}},{key:"item.createdDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocaleTime(item.createdDate))+" ")]}},{key:"item.documents",fn:function(ref){
    var item = ref.item;
return [_c('LabelZip',{attrs:{"id":item.id,"number":item.number}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }